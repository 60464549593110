import Collapse from 'bootstrap/js/src/collapse';

const links = document.querySelectorAll('.navbar-nav .nav-link');
const buttons = document.querySelectorAll('.navbar-collapse .call-to-actions .btn');
const mobileNavbar = document.getElementById('navbarWSHSupport');
const bsCollapse = Collapse.getOrCreateInstance(mobileNavbar, {toggle: false});

const windowWidth = window.innerWidth;
if (windowWidth < 992) {
    links.forEach((link) => {
        link.addEventListener('click', () => {
            bsCollapse.hide();
        });
    });

    buttons.forEach((button) => {
        button.addEventListener('click', () => {
            bsCollapse.hide();
        });
    });
}

const dropdowns = document.querySelectorAll('.nav-item.dropdown');

dropdowns.forEach((item) => {
    item.addEventListener('click', (e) => {
        const href = item.querySelector('.nav-link').getAttribute('href');
        if (href && href !== '#') {
            e.preventDefault(); // Verhindert das Standardverhalten des Links
            location.href = href;
        }
    });
});

const dropdownItems = document.querySelectorAll('.dropdown-item');

dropdownItems.forEach((item) => {
    item.addEventListener('click', (e) => {
        e.stopPropagation(); // Verhindert das Event-Bubbling
    });
});

dropdowns.forEach(function (dropdown) {
    const link = dropdown.querySelector('.dropdown-toggle');
    const menu = dropdown.querySelector('.dropdown-menu');

    link.addEventListener('mouseenter', function () {
        menu.classList.add('show');
        link.setAttribute('aria-expanded', 'true');
    });

    link.addEventListener('mouseleave', function () {
        setTimeout(function () {
            if (!menu.matches(':hover')) {
                menu.classList.remove('show');
                link.setAttribute('aria-expanded', 'false');
            }
        }, 300);
    });

    menu.addEventListener('mouseleave', function () {
        menu.classList.remove('show');
        link.setAttribute('aria-expanded', 'false');
    });

    menu.addEventListener('mouseenter', function () {
        menu.classList.add('show');
        link.setAttribute('aria-expanded', 'true');
    });
});